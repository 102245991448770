import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'

// 邀请有奖列表
export function findPage(data) {
    return axios({
        url: '/mgmt/member/relation/pageList',
        method: 'get',
        params: data
    })
}
// 换绑推荐人
export function changeSharer(data) {
    return axios({
        url: 'mgmt/member/relation/changeSharer',
        method: 'post',
        data
    })
}

// 解绑
export function reqUnbind(data) {
    return axios({
        url: '/mgmt/member/relation/unbind',
        method: 'post',
        data
    })
}


// 绑定上级
export function bindMyParent(data) {
    return axios({
        url: 'mgmt/member/relation/bindMyParent',
        method: 'post',
        data
    })
}

// 导出Excel模板
export function exportBindRelationTemplate(data) {
    return axios({
      url: '/mgmt/member/relation/exportBindRelationTemplate',
      method: 'get',
      responseType: 'blob',
      params: data
    })
  }
  // 导入Excel绑定
  export function importBindRelation(data) {
    return axios({
      url: '/mgmt/member/relation/importBindRelation',
      method: 'post',
      data
    }, { headers: {
      'Content-Type': 'application/octet-stream'
    }})
  }
  
  // 导入Excel换绑
  export function importChangeBindRelation(data) {
    return axios({
      url: '/mgmt/member/relation/importChangeBindRelation',
      method: 'post',
      data
    }, { headers: {
      'Content-Type': 'application/octet-stream'
    }})
  }


// 直推列表
export function getDirectUsers(data) {
    return axios({
        url: '/mgmt/member/relation/getDirectUsers',
        method: 'get',
        params: data
    })
}
  
// 直推列表
export function getDirectUserTeams(data) {
    return axios({
        url: '/mgmt/member/relation/getDirectUserTeams',
        method: 'get',
        params: data
    })
}