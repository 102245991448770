import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'
// import commonMethod from '@/utils/commonMethod'


// 分账公司
export function findCompanyPage(data) {
  return axios({
    url: '/mgmt/company/getPage',
    method: 'get',
    params: data
  })
}

// 新增公司
export function companyAdd(data) {
  return axios({
    url: '/mgmt/company/add',
    method: 'post',
    data
  })
}

// 编辑公司
export function companyUpdate(data) {
  return axios({
    url: '/mgmt/company/update',
    method: 'post',
    data
  })
}

// 分账公司详情
export function getDetail(data) {
  return axios({
    url: '/mgmt/company/detail/' + data.id,
    method: 'get',
    params: data
  })
}

// 分账公司钱包
export function getWallet(data) {
  return axios({
    url: '/mgmt/company/account/wallet/getWallet/' + data.id,
    method: 'get',
    params: data
  })
}

// 分账公司钱包流水
export function getWalletLog(data) {
  return axios({
    url: '/mgmt/company/account/wallet/getWalletLog',
    method: 'get',
    params: data
  })
}

// 充值分账公司钱包
export function putRecharge(data) {
  return axios({
    url: '/mgmt/company/account/wallet/recharge',
    method: 'post',
    data
  })
}

// 是否开启公司分账配置
export function enableSharingAcct(data) {
  return axios({
    url: '/mgmt/company/enableSharingAcct',
    method: 'post',
    data
  })
}