import axios from '../../axios'
// import { export_blob } from '@/utils/exportFile'

// ------------------------------------------- 机构列表
// 管理员使用机构列表
export function findPage(data) {
  return axios({
    url: '/mgmt/agent/list',
    method: 'get',
    params: data
  })
}

// 获取当前机构信息，如果是系统管理员则返回null

export function getAgentInfo(data) {
  return axios({
    url: '/mgmt/agent/info',
    method: 'get'
  })
}
// 卡详情
export function getDetail(data) {
  return axios({
    url: '/mgmt/member/card/detail',
    method: 'get',
    params: data
  })
}
// 批量生成
export function generateCard(data) {
  return axios({
    url: '/mgmt/member/card/generateCard',
    method: 'post',
    data
  })
}
// 添加机构
export function addAgent(data) {
  return axios({
    url: '/mgmt/agent/add',
    method: 'post',
    data
  })
}
// 编辑机构
export function updateAgent(data) {
  return axios({
    url: '/mgmt/agent/update',
    method: 'post',
    data
  })
}

export function getWalletLog(data) {
  // /mgmt/pharmacy/wallet/getPharmacyWalletLog?sourceTarget=1&sourceType=1&curPage=1&pageSize=10&pharmacyId=3
  return axios({
    url: '/mgmt/agent/wallet/getAgentWalletLog',
    method: 'get',
    params: data
  })
}
// 获取钱包余额
export const getBalance = (data, pageVo) => {
  return axios({
    // url: basePath + '/wallet' + pageVo.pageSize + '/' + pageVo.pageNum,
    url: '/mgmt/agent/wallet/getAgentWallet/' + data.id,
    method: 'get',
    params: data
  })
}
// 获取机构对公账户
export const getCorporateAccount = (data, pageVo) => {
  return axios({
    // url: basePath + '/wallet' + pageVo.pageSize + '/' + pageVo.pageNum,
    url: '/mgmt/agent/corporate/account/getCorporateAccount/' + data.id,
    method: 'get',
    params: data
  })
}


// 对账明细 分页查询
export const findPageWallet = (data, pageVo) => {
  return axios({
    url: '/mgmt/order/agent/settle/getPage',
    method: 'get',
    params: data
  })
}

// 获取机构对账结算钱包
export function getReconciliationWallet(data) {
  return axios({
    url: '/mgmt/agent/wallet/getReconciliationWallet',
    method: 'post',
    data
  })
}


// 对账结算钱包流水明细
export const findPageReconciliation = (data, pageVo) => {
  return axios({
    url: '/mgmt/reconciliation/wallet/log/getPage',
    method: 'get',
    params: data
  })
}
// 对账订单流水明细
export const findOrderPage = (data, pageVo) => {
  return axios({
    url: '/mgmt/reconciliation/order/getReconciliationOrderPage',
    method: 'get',
    params: data
  })
}

// 获取已结算总金额
export const getTotalAmount = (data, pageVo) => {
  return axios({
    url: '/mgmt/reconciliation/wallet/getTotalAmount',
    method: 'get',
    params: data
  })
}

// 获取对账列表(一级机构使用)
export const getReconciliationPage = (data, pageVo) => {
  return axios({
    url: '/mgmt/reconciliation/wallet/getReconciliationPage',
    method: 'get',
    params: data
  })
}

// 机构钱包结算申请
export function settleApply(data) {
  return axios({
    url: '/mgmt/agent/wallet/settleApply',
    method: 'post',
    data
  })
}

// 查询sdk配置信息
export const getSDKSetting = (data, pageVo) => {
  return axios({
    url: `/mgmt/agent/setting/${data}`,
    method: 'get',
  })
}

// 编辑sdk配置信息
export function updateSDKSetting(data) {
  return axios({
    url: `/mgmt/agent/setting/update/${data.agentNo}`,
    method: 'post',
    data
  })
}

// export function exportExcel(data, loadingMessage, id) {
//   return axios({
//     url: '/mgmt/medicine/drugs/_export',
//     method: 'post',
//     responseType: 'blob',
//     data
//   })
//     .then(response => {
//       export_blob(response, '药品信息.xls')
//       if (loadingMessage) {
//         setTimeout(function() {
//           loadingMessage.close()
//         }, 100)
//       }
//     })
//     .catch(function(error) {
//       loadingMessage.close()
//       console.log(error)
//     })
// }
